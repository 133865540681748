<template>
  <div class="address">
    <router-view> </router-view>
     <div class="home-btn">
      <p>地址：陕西省西安市未央区经开万科中心15层 | 电话：029-6888-2888</p>

      
      <div style="width: 400px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010402000448"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="../assets/image/BA.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            陕公网安备 61010402000732号
          </p></a
        >
        <a
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          href="https://beian.miit.gov.cn/"
        >
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            陕ICP备2023000064号
          </p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lng: 108.954721, lat: 34.355478 },
      zoom: 17,
    };
  },
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(108.954308, 34.355322);
      map.centerAndZoom(point, 17);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 6, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
  },
};
</script>

<style lang="scss" scoped>
.address-img {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
  }
}
.shuju {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 130px;
  // background-color: pink;
  box-sizing: border-box;
  margin-top: 50px;
  padding: 0 15%;
  border-bottom: 1px solid #ccc;
  > div {
    width: 25%;
    text-align: center;
    h3 {
      font-size: 35px;
      font-weight: 400;
      color: rgb(94, 93, 93);
    }
    p {
      color: #9d9d9d;
    }
  }
}
.jianjie {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  // background-color: pink;
  box-sizing: border-box;

  border-bottom: 1px solid #ccc;
  > div {
    position: relative;
    width: 50%;
    img {
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translateY(-50%);
    }
    h1 {
      margin-top: 90px;
      color: rgb(94, 93, 93);
      font-weight: 400;
    }
    h3 {
      color: #9d9d9d;
    }
    p {
      padding-right: 200px;
      font-size: 14px;
      color: #9d9d9d;
    }
  }
}
.lianxi {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 25px;
  color: #3f8e0c;
}
.lianxiadress {
  width: 100%;

  text-align: center;
  font-size: 14px;
  color: #9d9d9d;
}
.address-center {
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 10px;
  height: 350px;
  //   background-color: pink;
  .address-center-left {
    box-sizing: border-box;
    width: 50%;
    height: 350px;
    padding-left: 20%;
    h3 {
      margin-bottom: 30px;
      color: #4b4b4b;
    }
    p {
      margin-top: 15px;
      color: #9d9d9d;
    }
  }
  .address-center-right {
    box-sizing: border-box;
    width: 50%;
    height: 350px;
    padding-left: 5%;
  }
}
.home-btn {
  p {
    text-align: center;
    color: #9d9d9d;
  }
  > a {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #9d9d9d;
  }
  .beian {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    a {
      color: #9d9d9d;
    }
  }
}
</style>